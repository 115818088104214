<!--
 * @Description: 服务单质检详情
 * @Author: ChenXueLin
 * @Date: 2021-09-22 11:11:56
 * @LastEditTime: 2022-07-28 17:46:21
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
      v-loading="loading"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <detail-title-content
            :btnType="btnType"
            :btnText="'质检状态:' + baseInfoForm.qTStatus"
          >
            <template v-slot:leftButtons>
              <div
                :class="[
                  { yellowBtn: baseInfoForm.approveStatusCode == 1 },
                  {
                    greenBtn:
                      baseInfoForm.approveStatusCode == 2 ||
                      baseInfoForm.approveStatusCode == 3
                  },
                  'approveStatus'
                ]"
              >
                审核状态:{{ baseInfoForm.approveStatus }}
              </div>
            </template>
          </detail-title-content>
          <div class="baseInfo-content-wrap">
            <div class="detailInfo-content">
              <!-- 基本信息 start -->
              <div class="baseInfo-box">
                <div class="edit-title">基本信息</div>
                <el-form
                  label-width="140px"
                  :model="baseInfoForm"
                  class="editForm mbForm"
                >
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="质检ID" prop="inspectionId">
                        {{ baseInfoForm.inspectionId }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="质检员" prop="QTName">
                        {{ baseInfoForm.qTName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="质检时间" prop="QTTimeStr">
                        {{ baseInfoForm.qTTime }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="审核人" prop="approveName">
                        {{ baseInfoForm.approveName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="审核时间" prop="approveTimeStr">
                        {{ baseInfoForm.approveTimeStr }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
              <!-- 服务信息 -->
              <div class="baseInfo-box">
                <div class="edit-title">服务信息</div>
                <el-form
                  label-width="140px"
                  class="editForm"
                  :model="baseInfoForm"
                >
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="客户名称" prop="corpName">
                        {{ baseInfoForm.corpName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="联系人名称" prop="contactName">
                        {{ baseInfoForm.contactName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="联系人电话" prop="contactPhone">
                        {{ baseInfoForm.contactPhone }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
                <el-form
                  label-width="140px"
                  :model="baseInfoForm"
                  class="mbForm"
                >
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="服务单号" prop="serviceNo">
                        {{ baseInfoForm.serviceNo }}
                        <el-button type="text" @click="toDetail"
                          >查看服务单详情</el-button
                        >
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="服务单类型" prop="serviceType">
                        {{ baseInfoForm.serviceType }}
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :span="8">
                      <el-form-item label="联系人电话" prop="contactPhone">
                        {{ baseInfoForm.contactPhone }}
                      </el-form-item>
                    </el-col> -->
                    <el-col :span="8">
                      <el-form-item label="工单来源" prop="workSource">
                        {{ baseInfoForm.workSource }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="工单创建人" prop="createdBy">
                        {{ baseInfoForm.createdBy }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item
                        label="工单创建时间"
                        prop="workCreatedTimeStr"
                      >
                        {{ baseInfoForm.workCreatedTimeStr }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="要求完成时间" prop="expactTimeStr">
                        {{ baseInfoForm.expactTimeStr }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item
                        label="首次回复时间/处理"
                        prop="actualTimeStr"
                      >
                        {{ baseInfoForm.actualTimeStr }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="任务交接时间" prop="assignTimeStr">
                        {{ baseInfoForm.assignTimeStr }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="指派处理人" prop="assignName">
                        {{ baseInfoForm.assignName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="处理人名称" prop="solveName">
                        {{ baseInfoForm.solveName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="场景名称" prop="sceneNames">
                        {{ baseInfoForm.sceneNames }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
              <!-- 质检评分 -->
              <div>
                <div class="edit-title">质检评分</div>
                <el-form
                  label-width="140px"
                  style="margin-top:20px;"
                  class="inspectionForm"
                  :model="inspectionForm"
                  :rules="inspectionFormRules"
                  ref="inspectionForm"
                >
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="质检结果" prop="qualityStatusCode">
                        <e6-vr-select
                          v-model="inspectionForm.qualityStatusCode"
                          :disabled="
                            baseInfoForm.approveStatusCode == 2 ||
                              baseInfoForm.approveStatusCode == 3
                          "
                          :data="QTResultList"
                          placeholder="质检结果"
                          title="质检结果"
                          clearable
                          :props="{
                            id: 'codeValue',
                            label: 'codeName'
                          }"
                        ></e6-vr-select>
                      </el-form-item>
                      <el-form-item
                        label="回复时效考核"
                        prop="responseTimeInspection"
                      >
                        <e6-vr-select
                          :disabled="
                            baseInfoForm.approveStatusCode == 2 ||
                              baseInfoForm.approveStatusCode == 3
                          "
                          v-model="inspectionForm.responseTimeInspection"
                          :data="replyEnumList"
                          placeholder="回复时效考核"
                          title="回复时效考核"
                          clearable
                          :props="{
                            id: 'codeValue',
                            label: 'codeName'
                          }"
                        ></e6-vr-select>
                      </el-form-item>
                      <el-form-item label="任务质检考核" prop="taskInspection">
                        <e6-vr-select
                          v-model="inspectionForm.taskInspection"
                          :data="taskQTExamEnumList"
                          placeholder="任务质检考核"
                          title="任务质检考核"
                          clearable
                          :disabled="
                            baseInfoForm.approveStatusCode == 2 ||
                              baseInfoForm.approveStatusCode == 3
                          "
                          :props="{
                            id: 'codeValue',
                            label: 'codeName'
                          }"
                        ></e6-vr-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="16">
                      <el-form-item label="质检建议" prop="qualityAdvice">
                        <el-input
                          :disabled="
                            baseInfoForm.approveStatusCode == 2 ||
                              baseInfoForm.approveStatusCode == 3
                          "
                          type="textarea"
                          v-model="inspectionForm.qualityAdvice"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
              <!-- 审核评分 -->
              <!-- 已质检才显示 -->
              <div
                v-if="
                  baseInfoForm.qTStatusCode == 2 ||
                    baseInfoForm.qTStatusCode == 3
                "
              >
                <div class="edit-title">审核评分</div>
                <el-form
                  label-width="140px"
                  style="margin-top:20px;"
                  class="inspectionForm"
                  :model="approveForm"
                  :rules="approveFormRules"
                  ref="approveForm"
                >
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="审核结果" prop="reviewStatusCode">
                        <e6-vr-select
                          :disabled="
                            baseInfoForm.approveStatusCode == 2 ||
                              baseInfoForm.approveStatusCode == 3
                          "
                          v-model="approveForm.reviewStatusCode"
                          :data="approveResultList"
                          placeholder="审核结果"
                          title="审核结果"
                          clearable
                          :props="{
                            id: 'codeValue',
                            label: 'codeName'
                          }"
                        ></e6-vr-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="16">
                      <el-form-item label="审核建议" prop="reviewAdvice">
                        <el-input
                          :disabled="
                            baseInfoForm.approveStatusCode == 2 ||
                              baseInfoForm.approveStatusCode == 3
                          "
                          type="textarea"
                          v-model="approveForm.reviewAdvice"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <div
          class="edit-wrapper__footer"
          v-if="baseInfoForm.approveStatusCode == 1"
        >
          <el-button class="cancel" @click="handleGoBack">取消</el-button>

          <el-button
            type="primary"
            @click="confirm"
            v-if="
              (baseInfoForm.qTStatusCode == 2 ||
                baseInfoForm.qTStatusCode == 3) &&
                $route.meta.operationIds.includes(136)
            "
            >确定审核</el-button
          >
          <el-button
            type="primary"
            @click="confirm"
            v-if="
              baseInfoForm.qTStatusCode == 1 &&
                $route.meta.operationIds.includes(73)
            "
            >确定质检</el-button
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import detailTitleContent from "@/components/detailTitleContent";
import goBack from "@/mixins/goBack";
import {
  getServiceQTDetail,
  confirmServiceQT,
  approveServiceQT,
  findDownList
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "serviceQualityDetail",
  components: {
    detailTitleContent
  },
  data() {
    return {
      baseInfoForm: {}, //基本信息
      inspectionId: "",
      QTResultList: [], //质检结果
      replyEnumList: [], //回复时效
      taskQTExamEnumList: [], //任务质检考核
      approveResultList: [], //审核结果
      inspectionForm: {
        qualityStatusCode: "", //质检结果
        responseTimeInspection: "", //回复时效考核
        taskInspection: "", //任务质检考核
        qualityAdvice: "" //质检建议
      }, //确认质检form
      inspectionFormRules: {
        qualityStatusCode: [
          {
            required: true,
            message: "请选择质检结果",
            trigger: ["blur", "change"]
          }
        ],
        responseTimeInspection: [
          {
            required: true,
            message: "请选择回复时效考核",
            trigger: ["blur", "change"]
          }
        ],
        taskInspection: [
          {
            required: true,
            message: "请选择任务质检考核",
            trigger: ["blur", "change"]
          }
        ]
      },
      //审核结果
      approveForm: {
        reviewStatusCode: "", //审核结果
        reviewAdvice: "" //审核建议
      },
      approveFormRules: {
        reviewStatusCode: [
          {
            required: true,
            message: "请选择审核结果",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  mixins: [base, listPage, listPageReszie, goBack],
  computed: {
    btnType() {
      let value;
      if (this.baseInfoForm.qTStatusCode == 1) {
        value = "blueBtn";
      }
      if (
        this.baseInfoForm.qTStatusCode == 2 ||
        this.baseInfoForm.qTStatusCode == 3
      ) {
        value = "greenBtn";
      }
      return value;
    }
  },
  watch: {},
  created() {
    this.initForm();
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  methods: {
    initForm() {
      this.initData();
      let hasParamsRoute = this.getHasParamsRoute();
      this.inspectionId = hasParamsRoute.params.id;
      if (this.inspectionId) {
        this.getDetail();
      }
    },
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [
          findDownList([
            "QTResult",
            "ReplyEnum",
            "TaskQTExamEnum",
            "ApproveResult"
          ])
        ];
        let [statusRes] = await Promise.all(promiseList);
        //质检结果
        this.QTResultList = this.getFreezeResponse(statusRes, {
          path: "data.QTResult"
        });
        //回复时效考核
        this.replyEnumList = this.getFreezeResponse(statusRes, {
          path: "data.ReplyEnum"
        });
        //任务质检考核
        this.taskQTExamEnumList = this.getFreezeResponse(statusRes, {
          path: "data.TaskQTExamEnum"
        });
        //审核结果
        this.approveResultList = this.getFreezeResponse(statusRes, {
          path: "data.ApproveResult"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //查询服务单质检详情
    async getDetail() {
      try {
        this.loading = true;
        let res = await getServiceQTDetail({ inspectionId: this.inspectionId });
        this.baseInfoForm = res.data;
        // 质检信息回显
        let form = this.baseInfoForm;
        this.inspectionForm.qualityStatusCode =
          form.qTStatusCode && form.qTStatusCode != 1
            ? form.qTStatusCode.toString()
            : ""; //质检结果
        this.inspectionForm.responseTimeInspection = form.responseTimeInspectionCode
          ? form.responseTimeInspectionCode.toString()
          : ""; //回复时效
        this.inspectionForm.taskInspection = form.taskInspectionCode
          ? form.taskInspectionCode.toString()
          : ""; //任务质检考核
        this.inspectionForm.qualityAdvice = form.qualityAdvice;
        // 审核信息回显
        this.approveForm.reviewStatusCode =
          form.approveStatusCode && form.approveStatusCode != 1
            ? form.approveStatusCode.toString()
            : ""; //审核结果
        this.approveForm.reviewAdvice = form.reviewAdvice; //审核建议
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击确定
    confirm() {
      if (this.baseInfoForm.qTStatusCode == 1) {
        //未质检
        this.$refs.inspectionForm.validate(valid1 => {
          if (valid1) {
            this.confirmServiceQTReq();
          }
        });
      } else {
        //已质检未审核
        this.$refs.inspectionForm.validate(valid1 => {
          this.$refs.approveForm.validate(valid2 => {
            if (valid1 && valid2) {
              //已质检未审核
              this.approveServiceQTReq();
            }
          });
        });
      }
    },
    //确认质检
    async confirmServiceQTReq() {
      try {
        this.loading = true;
        let res = await confirmServiceQT({
          ...this.inspectionForm,
          inspectionId: this.inspectionId
        });
        if (res.code == "OK") {
          this.$message.success("质检成功");
          this.inspectionForm = {
            qualityStatusCode: "", //质检结果
            responseTimeInspection: "", //回复时效考核
            taskInspection: "", //任务质检考核
            qualityAdvice: "" //质检建议
          };
          this.$refs.inspectionForm.resetFields();
          this.routerPush({
            name: "qualityInspectionCenter/serviceOrder",
            params: {
              refresh: false
            }
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //审核质检
    async approveServiceQTReq() {
      try {
        this.loading = true;
        let res = await approveServiceQT({
          ...this.inspectionForm,
          ...this.approveForm,
          inspectionId: this.inspectionId
        });
        if (res.code == "OK") {
          this.$message.success("审核成功");
          this.inspectionForm = {
            qualityStatusCode: "", //质检结果
            responseTimeInspection: "", //回复时效考核
            taskInspection: "", //任务质检考核
            qualityAdvice: "" //质检建议
          };
          this.approveForm = {
            reviewStatusCode: "", //审核结果
            reviewAdvice: "" //审核建议
          };
          this.$refs.inspectionForm.resetFields();
          this.$refs.approveForm.resetFields();
          this.routerPush({
            name: "qualityInspectionCenter/serviceOrder",
            params: {
              refresh: false
            }
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //查看服务单详情
    toDetail() {
      this.routerPush({
        name: "serviceListManage/serviceDetail",
        params: {
          refresh: true,
          serviceId: this.baseInfoForm.serviceId
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .baseInfo-box {
  .el-form {
    .el-form-item {
      margin-bottom: 5px;
    }
  }
}
/deep/.inspectionForm {
  .el-textarea {
    width: 400px;
  }
}
</style>
